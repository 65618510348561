define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu", ["exports", "@ember/component", "rsvp", "@ember/object", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/runloop", "@ember/service", "@ember/template-factory"], function (_exports, _component, _rsvp, _object, _component2, _tracking, _ajaxError, _ajax, _runloop, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comments-menu">
    {{#if this.expanded}}
      <PostVotingCommentsMenuComposer
        @id={{@id}}
        @onSave={{this.handleSave}}
        @onCancel={{this.closeComposer}}
      />
    {{else}}
      <DButton
        @display="link"
        @class="post-voting-comment-add-link"
        @action={{if
          this.currentUser
          this.expandComposer
          (route-action "showLogin")
        }}
        @label="post_voting.post.post_voting_comment.add"
      />
    {{/if}}
  
    {{#if this.hasMoreComments}}
      {{#unless this.expanded}}
        <span class="post-voting-comments-menu-separator"></span>
      {{/unless}}
  
      <div class="post-voting-comments-menu-show-more">
        <DButton
          @display="link"
          @class="post-voting-comments-menu-show-more-link"
          @action={{this.fetchComments}}
          @translatedLabel={{i18n
            "post_voting.post.post_voting_comment.show"
            count=@moreCommentCount
          }}
        />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "5iX5kWuE",
    "block": "[[[10,0],[14,0,\"post-voting-comments-menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@id\",\"@onSave\",\"@onCancel\"],[[30,1],[30,0,[\"handleSave\"]],[30,0,[\"closeComposer\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@display\",\"@class\",\"@action\",\"@label\"],[\"link\",\"post-voting-comment-add-link\",[52,[30,0,[\"currentUser\"]],[30,0,[\"expandComposer\"]],[28,[37,3],[\"showLogin\"],null]],\"post_voting.post.post_voting_comment.add\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"hasMoreComments\"]],[[[41,[51,[30,0,[\"expanded\"]]],[[[1,\"      \"],[10,1],[14,0,\"post-voting-comments-menu-separator\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-voting-comments-menu-show-more\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@display\",\"@class\",\"@action\",\"@translatedLabel\"],[\"link\",\"post-voting-comments-menu-show-more-link\",[30,0,[\"fetchComments\"]],[28,[37,5],[\"post_voting.post.post_voting_comment.show\"],[[\"count\"],[[30,2]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@id\",\"@moreCommentCount\"],false,[\"if\",\"post-voting-comments-menu-composer\",\"d-button\",\"route-action\",\"unless\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu.hbs",
    "isStrictMode": false
  });
  let PostVotingCommentsMenu = _exports.default = (_class = class PostVotingCommentsMenu extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "expanded", _descriptor2, this);
    }
    get hasMoreComments() {
      return this.args.moreCommentCount > 0;
    }
    handleSave(comment) {
      this.closeComposer();
      this.args.appendComments([comment]);
    }
    expandComposer() {
      this.expanded = true;
      this.fetchComments().then(() => {
        (0, _runloop.schedule)("afterRender", () => {
          const textArea = document.querySelector(`#post_${this.args.postNumber} .post-voting-comment-composer .post-voting-comment-composer-textarea`);
          textArea.focus();
          textArea.select();
        });
      });
    }
    closeComposer() {
      this.expanded = false;
    }
    fetchComments() {
      if (!this.args.id) {
        return _rsvp.Promise.resolve();
      }
      const data = {
        post_id: this.args.id,
        last_comment_id: this.args.lastCommentId
      };
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "GET",
        data
      }).then(response => {
        if (response.comments.length > 0) {
          this.args.appendComments(response.comments);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expanded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandComposer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "expandComposer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeComposer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeComposer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchComments", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchComments"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentsMenu);
});