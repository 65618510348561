define("discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-icon", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "post-voting-icon",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.post_voting_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        api.replaceIcon("notification.question_answer_user_commented", "comment");
      });
    }
  };
});