define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-button", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @class="btn-flat post-voting-button {{this.buttonClasses}}"
    @disabled={{this.disabled}}
    @icon={{this.iconName}}
    {{on "click" this.onClick}}
  />
  */
  {
    "id": "ZRXg1Sgh",
    "block": "[[[8,[39,0],[[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@class\",\"@disabled\",\"@icon\"],[[29,[\"btn-flat post-voting-button \",[30,0,[\"buttonClasses\"]]]],[30,0,[\"disabled\"]],[30,0,[\"iconName\"]]]],null]],[],false,[\"d-button\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-button.hbs",
    "isStrictMode": false
  });
  let PostVotingButton = _exports.default = (_class = class PostVotingButton extends _component2.default {
    get buttonClasses() {
      let classes = this.args.direction === "up" ? "post-voting-button-upvote" : "post-voting-button-downvote";
      if (this.args.voted) {
        classes += " post-voting-button-voted";
      }
      return classes;
    }
    get disabled() {
      return this.args.disabled || this.args.loading;
    }
    get iconName() {
      return this.args.direction === "up" ? "caret-up" : "caret-down";
    }
    onClick() {
      if (this.args.loading) {
        return false;
      }
      if (this.args.voted) {
        this.args.removeVote(this.args.direction);
      } else {
        this.args.vote(this.args.direction);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingButton);
});